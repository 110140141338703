/***
Bootstrap Line Tabs by @keenthemes
A component of Metronic Theme - #1 Selling Bootstrap 3 Admin Theme in Themeforest: http://j.mp/metronictheme
Licensed under MIT
***/

.content {
  padding: 30px;
  background: #f1f3fa;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

/* Portlet */
.portlet {
  background: #fff;
  padding: 20px;
}

.portlet.portlet-gray {
  background: #f7f7f7;
}

.portlet.portlet-bordered {
  border: 1px solid #eee;
}

/* Portlet Title */
.portlet-title {
  padding: 0;
    min-height: 40px;
    border-bottom: 1px solid #eee;
    margin-bottom: 18px;
}

.caption {
  float: left;
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
}

.caption.caption-primary .caption-subject,
.caption.caption-primary i {
  color: $brand-primary;
  font-weight: 200;
}

.caption.caption-success .caption-subject,
.caption.caption-success i {
  color: $brand-success;
  font-weight: 200;
}

.caption.caption-warning .caption-subject,
.caption.caption-warning i {
  color: $brand-warning;
  font-weight: 200;
}

.caption.caption-info .caption-subject,
.caption.caption-info i {
  color: $brand-info;
  font-weight: 200;
}

.caption.caption-danger .caption-subject,
.caption.caption-danger i {
  color: $brand-danger;
  font-weight: 200;
}

.caption i {
  color: #777;
  font-size: 15px;
  font-weight: 300;
  margin-top: 3px;
}

.caption-subject {
  color: #666;
  font-size: 16px;
  font-weight: 600;
}

.caption-helper {
  padding: 0;
  margin: 0;
  line-height: 13px;
  color: #9eacb4;
  font-size: 13px;
  font-weight: 400;
}

/* Inputs */
.inputs {
  float: right;
  display: inline-block;
  padding: 4px 0;
  margin-top: -10px;
}

.input-inline {
  width: 240px;
  display: inline-block;
  vertical-align: middle;
}

/* Tab */
.portlet-title > .nav-tabs {
  background: none;
  margin: 0;
  float: right;
  display: inline-block;
  border: 0;
}

.portlet-title > .nav-tabs > li {
  background: none;
  margin: 0;
  border: 0;
}

.portlet-title > .nav-tabs > li > a {
  background: none;
  border: 0;
  padding: 2px 10px 13px;
  color: #444;
}

.portlet-title > .nav-tabs > li.active,
.portlet-title > .nav-tabs > li.active:hover {
    border-bottom: 4px solid #f3565d;
    position: relative;
  }

  .portlet-title > .nav-tabs > li:hover {
    border-bottom: 4px solid #f29b9f;
  }

.portlet-title > .nav-tabs > li.active > a,
.portlet-title > .nav-tabs > li:hover > a {
  color: #333;
  background: #fff;
  border: 0;
}

