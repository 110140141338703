@import "site/variables";
@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "bootswatch";
@import "resources/assets/lib/font-awesome/scss/font-awesome";
@import "globals";
@import "mixins";
@import "footer";
@import "typeahead";
@import "portlets";
@import "app/admin";
@import "app/event";
@import "site/site";
@import "mods/mods";

html {
  min-height: 100%;
  position: relative;
}

body {
  padding-top: 44px;
  margin-bottom: 60px;
}

.voffset-xs {
  margin-top: $voffset-xs;
}

.voffset-sm {
  margin-top: $voffset-sm;
}

.voffset-md {
  margin-top: $voffset-md;
}

.voffset-lg {
  margin-top: $voffset-lg;
}


.popover {
  cursor: pointer;
}

.page-header {
 .btn-group {
    position: relative;
    top: 8px;
  }
}


.navbar-brand-image img {
  max-height:40px;
  margin-top: 2px;
}

.alert {
  small {
    color: inherit;
  }
  h4 {
    margin-bottom: 0;
  }
}

.text-empty {
  @extend .text-muted;
  text-align: center;
}

.row-center {
  display:flex;
  align-items:center;
}

.data-table-search {
  width: 100% !important;
}

#dataTables-buttonContainer {
  text-align: right;
}

.text-sm {
  font-size: .9em;
}

.massive {
  font-size: 80px;
}

.huge {
  font-size: 40px;
}

.large {
  font-size: 20px;
}

div.selectable {
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    background-color: $gray-lighter;
    border:1px solid $gray-light;
  }
}
