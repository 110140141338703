.nav-tabs {
  > li > a,
  > li > a:focus {
    margin-right: 0;
    background-color: transparent;
    border: none;
    color: $navbar-default-link-color;
    @include box-shadow(inset 0 -1px 0 #ddd);
    @include transition(all 0.2s);

    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-primary);
      color: $brand-primary;
    }
  }

  & > li.active > a,
  & > li.active > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-primary);
    color: $brand-primary;

    &:hover {
      border: none;
      color: $brand-primary;
    }
  }

  > li.warning > a,
  > li.warning > a:focus {
    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-warning);
      color: $brand-warning;
    }
  }

  > li.invitee > a,
  > li.invitee > a:focus {
    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-invitee);
      color: $brand-invitee;
    }
  }

  > li.design > a,
  > li.design > a:focus {
    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-design);
      color: $brand-design;
    }
  }

  > li.eula > a,
  > li.eula > a:focus {
    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-eula);
      color: $brand-eula;
    }
  }

  > li.primary > a,
  > li.primary > a:focus {
    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-primary);
      color: $brand-primary;
    }
  }

  > li.danger > a,
  > li.danger > a:focus {
    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-danger);
      color: $brand-danger;
    }
  }

  > li.danger.light > a,
  > li.danger.light > a:focus {
    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 lighten($brand-danger, 10));
      color: lighten($brand-danger, 10);
    }
  }

  > li.success > a,
  > li.success > a:focus {
    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-success);
      color: $brand-success;
    }
  }

  > li.info > a,
  > li.info > a:focus {
    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-info);
      color: $brand-info;
    }
  }

  & > li.active.warning > a,
  & > li.active.warning > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-warning);
    color: $brand-warning;

    &:hover {
      border: none;
      color: $brand-warning;
    }
  }

  & > li.active.invitee > a,
  & > li.active.invitee > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-invitee);
    color: $brand-invitee;

    &:hover {
      border: none;
      color: $brand-invitee;
    }
  }

  & > li.active.design > a,
  & > li.active.design > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-design);
    color: $brand-design;

    &:hover {
      border: none;
      color: $brand-design;
    }
  }

  & > li.active.eula > a,
  & > li.active.eula > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-eula);
    color: $brand-eula;

    &:hover {
      border: none;
      color: $brand-eula;
    }
  }

  & > li.active.success > a,
  & > li.active.success > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-success);
    color: $brand-success;

    &:hover {
      border: none;
      color: $brand-success;
    }
  }

  & > li.active.info > a,
  & > li.active.info > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-info);
    color: $brand-info;

    &:hover {
      border: none;
      color: $brand-info;
    }
  }

  & > li.active.danger > a,
  & > li.active.danger > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-danger);
    color: $brand-danger;

    &:hover {
      border: none;
      color: $brand-danger;
    }
  }

  & > li.active.danger.light > a,
  & > li.active.danger.light > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 lighten($brand-danger, 10));
    color: lighten($brand-danger, 10);

    &:hover {
      border: none;
      color: lighten($brand-danger, 10);
    }
  }

  & > li.active.primary > a,
  & > li.active.primary > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-primary);
    color: $brand-primary;

    &:hover {
      border: none;
      color: $brand-primary;
    }
  }

  & > li.disabled > a {
    @include box-shadow(inset 0 -1px 0 #ddd);
  }

  &.nav-justified {

    & > li > a,
    & > li > a:hover,
    & > li > a:focus,
    & > .active > a,
    & > .active > a:hover,
    & > .active > a:focus {
      border: none;
    }
  }

  .dropdown-menu {
    margin-top: 0;
  }
}