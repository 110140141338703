.panel-title {
  font-size: $font-size-base;
}

.panel-info.light {
  @include panel-variant(lighten($panel-info-border, 10), lighten($panel-info-text, 10), lighten($panel-info-heading-bg, 10), lighten($panel-info-border, 10));
}
.panel-default.light {
  @include panel-variant(lighten($panel-default-border, 10), lighten($panel-default-text, 10), lighten($panel-default-heading-bg, 10), lighten($panel-default-border, 10));
}
.panel-primary.light {
  @include panel-variant(lighten($panel-primary-border, 10), lighten($panel-primary-text, 10), lighten($panel-primary-heading-bg, 10), lighten($panel-primary-border, 10));
}
.panel-success.light {
  @include panel-variant(lighten($panel-success-border, 10), lighten($panel-success-text, 10), lighten($panel-success-heading-bg, 10), lighten($panel-success-border, 10));
}
.panel-warning.light {
  @include panel-variant(lighten($panel-warning-border, 10), lighten($panel-warning-text, 10), lighten($panel-warning-heading-bg, 10), lighten($panel-warning-border, 10));
}
.panel-invitee.light {
  @include panel-variant(lighten($panel-invitee-border, 10), lighten($panel-invitee-text, 10), lighten($panel-invitee-heading-bg, 10), lighten($panel-invitee-border, 10));
}

.panel-eula.light {
  @include panel-variant(lighten($panel-eula-border, 10), lighten($panel-eula-text, 10), lighten($panel-eula-heading-bg, 10), lighten($panel-eula-border, 10));
}

.panel-invitee {
  @include panel-variant($panel-invitee-border, $panel-invitee-text, $panel-invitee-heading-bg, $panel-invitee-border);
}

.panel-design {
  @include panel-variant($panel-design-border, $panel-design-text, $panel-design-heading-bg, $panel-design-border);
}

.panel-eula {
  @include panel-variant($panel-eula-border, $panel-eula-text, $panel-eula-heading-bg, $panel-eula-border);
}

.panel-body-success {
  background-color: lighten($brand-success, 40);
}

.panel-heading.panel-heading-xs {
  padding: 5px 10px;
}

.panel-body.panel-body-xs {
  padding: 5px;
}

.panel-body.panel-body-sm {
  padding: 10px;
}

.panel-heading.with-button > .panel-title {
  padding-top: $padding-base-vertical;
}
