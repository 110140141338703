.form-group.required .control-label:after {
  color: #d00;
  position: relative;
  margin-left: 2px;
  bottom: 5px;
  font-family: 'FontAwesome';
  font-weight: normal;
  font-size: 6px;
  content: "\f069";
}

.radio-label {
  position: relative;
  top: 3px;
}

.radio-label-disabled {
  color: #bbb;
}
