.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}

.btn-circle.btn-xs {
  width: 25px;
  height: 25px;
  padding: 4px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}
.btn-primary.btn-outline {
  color: #428bca;
}
.btn-success.btn-outline {
  color: #5cb85c;
}
.btn-info.btn-outline {
  color: #5bc0de;
}
.btn-warning.btn-outline {
  color: #f0ad4e;
}
.btn-danger.btn-outline {
  color: #d9534f;
}
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: white;
}

.row-actions .btn {
  float: right;
  margin: 0px 4px;
}

.row-actions .btn.btn-xs {
  float: right;
  margin: 0px 2px;
}

.btn-copy {
  @include button-variant($btn-copy-color, $btn-copy-bg, $btn-copy-border);
}

/* Actions */
.actions {
  float: right;
    display: inline-block;
}

.actions form {
  display: inline;
}

.actions a,button {
  margin-left: 3px;
}

.actions .btn {
  color: #666;
  font-size: 13px;
    background-color: #fff;
    border-color: #ccc;
    border-radius: 50px;
}

.actions .btn i {
  font-size: 12px;
}

.actions .btn:hover {
  background: #f2f2f2;
}

.actions .btn.btn-primary.active,
.actions .btn.btn-primary:hover {
  color: #fff;
  box-shadow: none;
  background: $brand-primary;
  border-color: $brand-primary;
}

.actions .btn.btn-danger.active,
.actions .btn.btn-danger:hover {
  color: #fff;
  box-shadow: none;
  background: $brand-danger;
  border-color: $brand-danger;
}

.actions .btn.btn-warning.active,
.actions .btn.btn-warning:hover {
  color: #fff;
  box-shadow: none;
  background: $brand-warning;
  border-color: $brand-warning;
}

.actions .btn.btn-info.active,
.actions .btn.btn-info:hover {
  color: #fff;
  box-shadow: none;
  background: $brand-info;
  border-color: $brand-info;
}

.actions .btn.btn-success.active,
.actions .btn.btn-success:hover {
  color: #fff;
  box-shadow: none;
  background: $brand-success;
  border-color: $brand-success;
}

.actions .btn.btn-copy.active,
.actions .btn.btn-copy:hover {
  color: #fff;
  box-shadow: none;
  background: $brand-copy;
  border-color: $brand-copy;
}